@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

/* header */

.navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
}

.navmenulist {
    max-width: 15%;
}

.headergpt ul {
    padding-left: 0px !important;
    list-style-type: none;
    font-family: 'Space Mono', monospace;
}

.headergpt li a {
    color: #fff;
}

.headergpt li a:hover {
    color: #FF67FF !important;
    /* color: var(--greens) !important; */
    cursor: pointer;
    transition: 0.5s;
}

.homeheaders {
    position: absolute;
    top: 28%;
    left: 13%;
}

.typed-cursor {
    color: #FF67FF !important;
}

/* .section2 .typed-cursor{
    color: #fff !important;
} */
.Homepage {
    padding: 0px 0px;
    font-family: 'Space Mono', monospace;
}

.openbook {
    background: url("./Assets//images/slide/ship2.gif");
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.one1 {
    position: absolute;
    right: 20%;
    top: 18%;
}

.ship {
    background: url("./Assets//images/slide/dock2.jpg");
    background-repeat: no-repeat;
    /* width: 100%; */
    /* background-size: 100% 100%; */
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: 100% 100%;
    background-position: center;
    filter: brightness(0.9);
    /* backdrop-filter: red; */
    /* z-index: 3; */
}

/* .ship::before{
    content: "";
    background: url("./Assets/images/slide/tech.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100% !important;
    height: 100vh;
    position: absolute;
    z-index: 0;
} */
.section4 .ship {
    /* filter: brightness(0.5) !important; */
}

.section4 .ship1::before {
    content: "";
    background: url("./Assets/images/slide/stars.png") !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 66%;
    min-width: 66%;
    /* height: 100%; */
    height: 90%;
    max-height: 90%;
    min-height: 90%;
    background-size: 100% 100% !important;
    position: absolute;
    top: 6%;
    left: 17%;
    z-index: -1;
    border-radius: 10%;
}

.section5 .ship1::before {
    content: "";
    background: url("./Assets/images/slide/planetbg.png") !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 72.5%;
    min-width: 72.5%;
    /* height: 100%; */
    height: 93%;
    max-height: 93%;
    min-height: 93%;
    background-size: 100% 100% !important;
    position: absolute;
    top: 4%;
    left: 14.7%;
    z-index: -1;
    border-radius: 8% 12% 8% 12%;
}

/* .ship::after{
    content: "";
    background: url("./Assets/images/slide/screen.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
} */
.openbook::before {
    content: "";
    background: url("./Assets/images/slide/star-travel.gif");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
}

.section2 .openbook::before {
    content: "";
    background: url("./Assets/images/slide/bg.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
}

.homesec {
    position: absolute;
    /* top: 36%;
    left: 35%; */
    text-align: center;
    font-family: 'Space Mono', monospace;
    Left: 50% !important;
    top: 40% !important;
    transform: translate(-50%, -50%);
}

.hometitle {
    color: #FF67FF;
    font-size: 40px;
    font-family: 'Space Mono', monospace;
    font-weight: 700;
}

.subtitle {
    color: #00D1FF
}

.topgif {
    max-width: 34%;
    position: absolute;
    top: 3%;
    left: 33%;
}

.bottomgif {
    max-width: 34%;
    position: absolute;
    bottom: 20%;
    left: 33%;
    transform: rotateX(180deg);
}

.targetcircle {
    position: absolute;
    max-width: 24%;
    left: 39%;
    top: 20%;
    filter: brightness(0.3);
    z-index: -1;
}

.globecircle {
    position: absolute;
    max-width: 7%;
    bottom: 8%;
    right: 30%;
}

.hud {
    position: absolute;
    bottom: 20%;
    max-width: 10%;
    left: 13%;
}

.redgredient {
    position: absolute;
    background-image: linear-gradient(rgb(255 0 0 / 18%), rgb(255 0 0 / 18%));
    max-width: 600px;
    width: 600px;
    filter: blur(50px);
    height: 200px;
    z-index: -1;
    transform: rotate(357deg);
    top: -18%;
    left: 28%;
}

.violentgredient {
    position: absolute;
    background-image: linear-gradient(#170e2b, #170e2b);
    max-width: 500px;
    width: 500px;
    filter: blur(50px);
    height: 200px;
    z-index: -1;
    transform: rotate(357deg);
    bottom: -4%;
    left: 30%;
}

.loadimg {
    position: absolute;
    max-width: 12%;
    transform: rotate(342deg);
    top: 14%;
    left: 14%;
}

.launch {
    position: absolute;
    bottom: 22%;
    right: 20%;
    max-width: 13%;
}

.launch-name {
    position: absolute;
    bottom: 32%;
    right: 24%;
    max-width: 12%;
    color: #CB08C5;
    text-transform: uppercase;
    font-size: 18px !important;
    font-weight: 700;
    font-family: 'Space Mono', monospace;
}

.loopsimg {
    position: absolute;
    top: 12%;
    right: 9%;
    max-width: 20%;
    transform: rotate(11deg);
    /* filter: brightness(0.5); */
    /* filter: blur(1px); */
}

.launchpad .loopsimg {
    right: 2%;
}

.button.type1 {
    color: var(--greens);
    position: relative;
    padding: 8px 20px !important;
    border: 1px solid #ffff;
    border-radius: 4px;
}

.button.type1.type1::after,
.button.type1.type1::before {
    content: '';
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 1px solid #ffff;
    transition: all 0.6s ease;
    border-radius: 4px;
}

.button.type1.type1::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #ffff;
    border-right-color: #ffff;
}

.button.type1.type1::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: #ffff;
    border-left-color: #ffff;
}

.button.type1.type1:hover:after,
.button.type1.type1:hover:before {
    width: 100%;
    height: 100%;
}

.launchpad .targetcircle {
    top: -20%;
}

.lockedstake .targetcircle {
    top: -20%;
}

.loopsleftimg {
    position: absolute;
    top: 12%;
    left: 9%;
    max-width: 20%;
    transform: rotate(345deg);
    /* filter: brightness(0.5); */
    /* filter: blur(1px); */
}

.launchpad .loopsleftimg {
    left: 2%;
}

.navmenus {
    position: absolute;
    right: 12%;
    top: 16%;
    max-width: 7%;
}


/* section2 */

.seccon {
    padding: 10%;
}

.seccon1 {
    padding: 8% 10%;
}

.seccon2 {
    padding: 5% 10%;
}

.f-13 {
    font-size: 13px;
}

.f-11 {
    font-size: 10px;
}

.fw-700 {
    font-weight: 700;
}

.planetcircle {
    position: absolute;
    max-width: 5%;
    bottom: 42%;
    left: 46%;
}

.para {
    position: relative;
}

.topborder {
    position: absolute;
    max-width: 7%;
    left: 0;
}

.bottomborder {
    position: absolute;
    max-width: 7%;
    right: 6%;
    bottom: 1%;
}

.subparas {
    padding: 10px 10px;
}

.hudgifs {
    position: absolute;
    max-width: 100%;
    left: -24%;
    transform: rotateX(180deg);
    bottom: -15%;
}

.navigationmenu {
    position: fixed;
    top: 25%;
    right: 10%;
    z-index: 2;
}

.navborder {
    max-width: 30%;
}

.navborder1 {
    position: absolute;
    top: -13%;
    right: 58%;
    max-width: 100%;
}

.linear-rel {
    position: relative;
    z-index: 2;
}

.linearbg {
    position: absolute;
    max-width: 100%;
    top: -26%;
    width: 100%;
    z-index: -1;
}

.navigationmenu ul {
    padding-left: 0px !important;
    list-style-type: none;
    position: absolute;
    top: 24%;
    left: -12%;
}

.navigationmenu li {
    cursor: pointer;
}

.grayborder {
    max-width: 60%;
    /* width: 100%; */
}

.section3 {
    height: 100vh;
}

/* section3 */
.glitchimg {
    height: 100vh;

}

.screenimg {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: -1;
}

.partner1 {
    max-width: 75% !important;
    /* filter: drop-shadow(2px 2px 26px #fff);
    background: transparent;
    box-shadow: 0 0 50px white; */
    /* animation: glow 3s infinite alternate; */
}

.partner1:hover {
    filter: drop-shadow(2px 2px 26px #fff)
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px -10px #ffffff26;
    }

    to {
        box-shadow: 0 0 10px 10px #ffffff26;
    }
}

/* section4 */
.section4 {
    position: relative;
}

.section4 h3,
h6 {
    color: #fff !important;
    font-weight: 700 !important;
}

.sustainable {
    max-width: 40%;
    margin: auto;
}

.f-12 {
    font-size: 12px;
}

.videoTag {
    width: 100%;
    height: 100vh;
    object-fit: fill;
    position: absolute;
    z-index: -1;
    /* padding: 4% 0%; */
}

.leftcamera {
    position: absolute;
    max-width: 10% !important;
    left: 9%;
    top: 6%;
    width: 100%;
}

.rightcamera {
    position: absolute;
    max-width: 10% !important;
    right: 9%;
    top: 6%;
    width: 100%;
}

.power-gauge {
    max-width: 80%;
    margin-top: -10%;
}

/* section5 */
.spaceship {
    background: url("./Assets/images/slide/dock.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    z-index: -1;
    /* position: relative;
    overflow: hidden;
    background-size: 100% 100%;
	background-position:center;	
    filter: brightness(0.9); */

}
.section5,.section4{
    background: url("./Assets/images/slide/dock2.jpg");
    background-repeat: no-repeat;
    padding: 8px 0px 8px 0px;
    background-position: center;
    background-size: 100% 100%;
    filter: brightness(0.9);
    position: relative;
    overflow: hidden;
    /* z-index: 7; */
}
.logoimg{
    /* left: 45%;
    position: absolute;
    top: 15%; */
    position: absolute;
    left: 50% !important;
    transform: translate(-50%, -50%);
    top: 16% !important;
}
.section5 h3,
h6 {
    color: #fff !important;
    font-weight: 700 !important;
}

.f-14 {
    font-size: 14px;
}

.spaceman {
    position: absolute;
    max-width: 40% !important;
    left: 8%;
    top: 34%;
    width: 100%;
    z-index: 1;
}

.section5 .seccon2 {
    padding: 4% 10% !important;
    max-width: 60rem;
}

.planet1 {
    max-width: 90%;
}

.section5 .row:nth-child(2) {
    padding-left: 5%;
}

.section5 .row:nth-child(3) {
    padding-left: 12%;
}

.section5 .row:nth-child(4) {
    padding-left: 16%;
}


/* .section5 .row:nth-child(5) {
    padding-left: 19%;
} */

.footer1 {
    border-top: 1px solid #80808096;
}


/* .parallax {
    background: url("./Assets/images/slide/dock.png");
    min-height: 1000px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .parrr{
    height:1000px;
  } */


/* .last{
    position: relative;
}
.frr {
    width: 150px;
    height: 150px;
    background-color: black;
}
.fade {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
}
.zoom {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.afterzoom {
    position: absolute;
    height: 200vh;
    width: 100%;
    background: red;
    overflow-x: auto;
} */

.headers {
    display: block;
}

.headers1 {
    display: none;
}

.offcanvas {
    background: url("./Assets/images/slide/star-travel.gif");
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
}

.react-transform-wrapper{
    padding-top: 6% !important;
}

@media only screen and (min-width: 1601px) and (max-width: 1920px) {
    .homesec {
        /* left: 38%; */
    }
    .homeheaders {
        top: 34%;
    }
    .partner1 {
       margin-top: 35% !important;
    }
    .mind{
        padding-top: 10% !important;
    }
    .section4 .ty .big{
        padding-top: 6% !important;
    }
    .big1{
        padding-top: 3% !important;
    }
    .big2{
        padding-top: 6% !important;
    }
    .footer1{
        margin-top: 6% !important;
    }
    .section5 .seccon2 {
        padding: 4% 4% !important;
    }
    /* .openbook{
        height: unset;
    } */
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {

    .para {
        margin-top: 12%;
    }

    .homesec {
        /* left: 36%; */
    }

    /* .launch {
        bottom: 21%;
        right: 18%;
        max-width: 17%;
    } */
}

@media only screen and (max-width: 1200px) {
    .launch {
        bottom: 18%;
        right: 17%;
        max-width: 20%;
    }

    .para {
        margin-top: 3%;
    }
    .plusminus{
        display: unset !important;
        justify-content: center !important;
    }
    .plusminus .justify-content-end{
        justify-content: center !important;
    }
}

@media only screen and (max-width: 1199px) {

    /* .section3 {
        height: unset;
    } */
    .section4 .ship::before {
        max-width: 78%;
        min-width: 78%;
        left: 11%;
    }

    .launch {
        bottom: 22%;
        right: 17%;
        max-width: 21%;
    }

    .navigationmenu {
        right: 0%;
    }

    .homeheaders {
        left: 10%;
    }

    .targetcircle {
        max-width: 35%;
        left: 33%;
    }

    .homesec {
        /* left: 32%; */
    }
}


@media only screen and (max-width: 991px) {

    .section3 {
        height: unset;
    }

    .headers {
        display: none;
    }

    .section5 .seccon2 {
        padding: 8% 10% !important;
    }

    .one1 {
        display: none;
    }

    .seccon2 {
        padding: 8% 10%;
    }

    .headers1 {
        display: block;
    }

    .targetcircle {
        max-width: 44%;
        left: 28%;
        top: 20%;
    }
    .launch {
        bottom: 21%;
        right: 14%;
        max-width: 24%;
    }
    .hudgifs {
        bottom: -1%;
    }
    .listimg {
        max-width: 10%;
    }
    .headers1 .btn-primary {
        background-color: transparent;
        border-color: transparent;
        text-align: left;
        position: fixed;
        /* top: 2%;
        left: 2%; */
        z-index: 777;
    }

    .offcanvas .btn-close {
        filter: invert(1);
    }

    .offcanvas-title {
        color: #FF67FF;
    }

    .offcanvas ul {
        padding-left: 0px !important;
        list-style-type: none !important;
    }

    .offcanvas li {
        padding-bottom: 15px;
    }

    .offcanvas li a {
        color: #fff;
    }

    .navmenulist {
        max-width: 8%;
    }

    .homeheaders {
        top: 0%;
        left: 0%;
    }

    .homesec {
        /* left: 22%; */
    }

    .navigationmenu {
        right: 0%;
    }

    .section4 .ship::before {
        max-width: 92%;
        min-width: 92%;
        left: 4%;
    }
    .logoimg {
        /* left: 44%; */
    }
}


@media only screen and (max-width: 767px) {
    .logoimg {
        /* left: 37%; */
    }
    .navigationmenu {
        display: none;
    }

    .partner1 {
        max-width: 100% !important;
    }

    .targetcircle {
        max-width: 44%;
        left: 27%;
        top: 22%;
    }

    .homesec {
        /* left: 10% !important; */
        padding: 0px 10px;
    }

    .navigationmenu {
        right: -14% !important;
    }

    .navborder {
        max-width: 20%;
    }

    .mt-top {
        margin-top: 12%;
    }

    .ty {
        height: 370px;
        overflow-y: auto;
        padding-left: 12px;
        padding-right: 12px;
    }

    .ty1 {
        height: 450px !important;
    }

    .ty::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    .ty::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    .ty::-webkit-scrollbar-thumb {
        background-color: #000000;
    }

    .launch {
        bottom: 22%;
        right: 16%;
        max-width: 28%;
    }

    .hudgifs {
        bottom: -30%;
    }

    .power-gauge,
    .planet1,
    .sustainable {
        max-width: 100%;
    }

    /* .hometitle {
        color: #FF67FF;
        font-size: 30px;
        font-family: 'Space Mono', monospace;
        font-weight: 700;
    } */
}

@media only screen and (max-width: 575px) {
    .ship{
        height: unset;
    }
    .logoimg {
        /* left: 27%; */
    }
    .react-transform-wrapper{
        padding-top: 46% !important;
    }

    .news .itemsfles {
        left: 10px;
    }

    .hudgifs {
        bottom: -10%;
    }

    .navigationmenu {
        right: -18% !important;
    }

    .homesec {
        /* left: 7% !important; */
    }

    .launch-name {
        bottom: 29%;
        right: 25%;
        font-size: 12px !important;
    }

    .offcanvas ul .ms-2,
    .ms-3,
    .ms-4,
    .ms-5 {
        margin-left: 0px !important;
    }

    .navmenulist {
        max-width: 12%;
    }

    .listimg {
        max-width: 14%;
    }

    .hometitle {
        font-size: 28px;
    }

    .subtitle {
        font-size: 10px;
    }

    .targetcircle {
        max-width: 70%;
        left: 14%;
        top: 25%;
    }

    .navborder {
        max-width: 12%;
    }

    .navborder1 {
        top: -30%;
        right: 78%;
        max-width: 75%;
    }

    .grayborder {
        max-width: 50%;
    }

    .navigationmenu ul {
        left: -20%;
    }

    .launch {
        bottom: 22%;
        right: 12%;
        max-width: 36%;
    }


    .linear-rel h5 {
        padding-left: 12px;
    }

    .f-14 {
        font-size: 11px;
    }

    .seccon2 {
        padding: 14% 10% 5% 10% !important;
    }

    .section4 .ship::before {
        max-width: 92%;
        min-width: 92%;
        left: 4%;
    }

    .section5 .spaceship::before {
        height: 94%;
        max-height: 94%;
        min-height: 94%;
    }

    .sub-heading {
        font-size: 11px;
    }

    .section4 h3 {
        font-size: 17px;
    }

    .section5 h3 {
        font-size: 17px;
    }

    .section5 .seccon2 {
        padding: 12% 10% 4% 10% !important;
    }

    .spaceman {
        max-width: 92% !important;
        left: -4%;
        top: 32%;
        z-index: -1;
    }

}


@media only screen and (max-width: 450px){
.logoimg {
    /* left: 31%; */
}
}